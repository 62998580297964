<template>
  <section id="daftar-persetujuan-direktur">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_belanja_id"
                  v-model="master_jenis_belanja_id"
                  required
                  name="master_jenis_belanja_id"
                  :options="referenceData.ref_jenis_belanja"
                  placeholder="Jenis Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'nama'">
                <b-badge
                  variant="light-info"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | moment }}
              </span>
              <!-- Status Asdep -->
              <span v-else-if="props.column.field === 'asdep_status'">
                <b-button
                  v-if="props.row.asdep_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.asdep_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'ASDEP', props.row.asdep_status)"
                >
                  {{ props.row.asdep_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Status Takel -->
              <span v-else-if="props.column.field === 'takel_status'">
                <b-button
                  v-if="props.row.takel_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.takel_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'TAKEL', props.row.takel_status)"
                >
                  {{ props.row.takel_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Status LAIP -->
              <span v-else-if="props.column.field === 'laip_status'">
                <b-button
                  v-if="props.row.laip_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.laip_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'LAIP', props.row.laip_status)"
                >
                  {{ props.row.laip_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  class="bg-darken"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Status LAIP -->
              <span v-else-if="props.column.field === 'bapenas_status'">
                <b-button
                  v-if="props.row.bapenas_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.bapenas_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'BAPPENAS', props.row.bapenas_status)"
                >
                  {{ props.row.bapenas_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  class="bg-darken"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <span v-else-if="props.column.field == 'can_approve'">
                <template v-if="userData.group_id > 49 && userData.group_id < 60">
                  <template v-if="yearBudget === '2023'">
                    <b-form-radio
                      v-model="props.row.mode"
                      :name="`some-${props.row.domain.toLowerCase()}-${props.row.id}`"
                      value="yes"
                      @change="konfirmReko(props.row, 'yes')"
                    >
                      <span
                        class="text-info font-weight-bolder"
                      >Dilanjutkan</span>
                    </b-form-radio>
                    <b-form-radio
                      v-model="props.row.mode"
                      :name="`some-${props.row.domain.toLowerCase()}-${props.row.id}`"
                      value="no"
                      @change="konfirmReko(props.row, 'no')"
                    >
                      <span
                        class="text-warning font-weight-bolder"
                      >Tidak Dilanjutkan</span>
                    </b-form-radio>
                  </template>
                  <template v-else>
                    <b-form-radio
                      v-model="props.row.mode"
                      :name="`some-${props.row.jenis_pengadaan_id}-${props.row.data_utama_id}`"
                      value="yes"
                      @change="konfirmReko(props.row, 'yes')"
                    >
                      <span
                        class="text-info font-weight-bolder"
                      >Dilanjutkan</span>
                    </b-form-radio>
                    <b-form-radio
                      v-model="props.row.mode"
                      :name="`some-${props.row.jenis_pengadaan_id}-${props.row.data_utama_id}`"
                      value="no"
                      @change="konfirmReko(props.row, 'no')"
                    >
                      <span
                        class="text-warning font-weight-bolder"
                      >Tidak Dilanjutkan</span>
                    </b-form-radio>
                  </template>
                </template>
              </span>
              <span v-else-if="props.column.field === 'teknis_status'">
                <b-button
                  v-b-tooltip.hover
                  :variant="statusVariant(props.row.teknis_status).class"
                  size="sm"
                  class="text-wrap"
                  style="max-width:120px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasi(props.row)"
                >
                  {{ statusVariant(props.row.teknis_status).title }}
                </b-button>
              </span>
              <!-- Column: Action -->
              <span
                v-else-if="props.column.field == 'action'"
              >
                <b-button
                  v-if="yearBudget === '2023'"
                  v-b-tooltip.hover
                  :to="{ name: 'rekomendasi-' + ((props.row.domain === 'INFRASTRUKTUR') ? 'infrastruktur' : 'aplikasi'), params: { id: props.row.id, type: 'view', utama: props.row.data_utama_id } }"
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  :title="`Detail ${(props.row.domain === 'INFRASTRUKTUR') ? 'Infrastruktur' : 'Aplikasi'}`"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="12"
                  /></b-button>
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  variant="primary"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  title="Detail Kegiatan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="12"
                  /></b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="secondary"
                  size="sm"
                  class="mr-0 mb-25"
                  title="File KAK"
                  @click="openFile(props.row.kak_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <p class="mx-1 my-0">
            Total <b>{{ totalRecords }}</b> Entries
          </p>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileKak"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-rekomendasi-direktur"
      ref="modal-rekomendasi-direktur"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <b-list-group
          class="mb-1"
          flush
        >
          <b-list-group-item
            v-for="(data, index) in listRekomendasi"
            :key="index"
            class="d-flex justify-content-between"
          >
            {{ data.recommendation }}
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <summary-rekomendasi
          :rekap-lanjut="listRekomendasi.continue"
          :rekap-tidak="listRekomendasi.postpone"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tambah-rekomendasi"
      ref="modal-tambah-rekomendasi"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
      @hidden="cancelReko()"
    >
      <b-card-text>
        <modul-rekomendasi-direktur
          :text-rekomendasi="textRekomendasi"
          @result="updateResult"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-confirm"
      ref="modal-confirm"
      modal-class="modal-info"
      centered
      title="Konfirmasi"
      @ok="simpanReko()"
      @hidden="cancelReko()"
    >
      <b-card-text>
        <h6>Anda yakin?</h6>
        <small>Klik tombol <strong>"Rekomendasi Tambahan"</strong> di bawah ini untuk menambahkan catatan</small>
      </b-card-text>

      <template v-if="errorStat">
        <b-alert
          variant="danger"
          class="mt-2"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            {{ errorMsg }}
          </div>
        </b-alert>
      </template>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <div class="mr-auto">
            <b-button
              variant="primary"
              size="sm"
              @click="openTambahRekomendasi(selectedInput, listRekoDir.appData)"
            >
              Rekomendasi Tambahan
            </b-button>
          </div>
          <div class="ml-auto">
            <b-button
              variant="secondary"
              size="sm"
              @click="cancelReko()"
            >
              Close
            </b-button>
            <b-button
              variant="info"
              size="sm"
              class="ml-25"
              @click="simpanReko()"
            >
              Ya, Simpan
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-warning2"
      ref="modal-warning2"
      hide-footer
      modal-class="modal-success"
      centered
      title="Sukses"
    >
      <b-card-text>
        Rekomendasi Berhasil Disimpan.
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      ok-only
      ok-variant="warning"
      ok-title="Ok"
      modal-class="modal-warning"
      centered
      title="Attention!"
    >
      <b-card-text>
        {{ alertText }}
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BListGroup, BListGroupItem, BAlert, BRow, BButton, BModal, BCol, BCard, BCardText, BBadge,
  BFormGroup, BFormInput, BFormRadio, VBTooltip, VBModal,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/persetujuanEs2Columns'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import VuePdfApp from 'vue-pdf-app'
import ModulRekomendasiDirektur from '../rekomendasi/ModulRekomendasiDirektur.vue'
import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BBadge,
    BButton,
    BCardText,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BFormRadio,
    SummaryRekomendasi,
    ModulRekomendasiDirektur,
    VuePdfApp,
    vSelect,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()
    const { oldColumns, newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const baseColumns = (yearBudget === '2023') ? oldColumns : newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'last_update',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      baseColumns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10 && role < 49 && role > 60) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      alertText: '',
      fileKak: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: '',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      columns: [],
      rows: [],
      app_rows: [],
      searchTerm: '',
      subSearchTerm: '',
      listRekomendasi: [],
      textRekomendasi: {
        program: {},
        mode: '',
        selectmode: '',
        selectval: '',
        daftar: [],
        select: [],
        note: '',
      },
      errorStat: false,
      errorMsg: '',
      selected: [],
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      prefix: '',
      nama_kegiatan: '',
      nama_app_infra: '',
      listRekoDir: '',
      selectedInput: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('prefix')) {
      this.prefix = localStorage.getItem('prefix')
      this.baseColumns[0].filterOptions.filterValue = this.prefix
    }
    if (localStorage.getItem('nama_kegiatan')) {
      this.nama_kegiatan = localStorage.getItem('nama_kegiatan')
      this.baseColumns[1].filterOptions.filterValue = this.nama_kegiatan
    }
    if (localStorage.getItem('nama_app_infra')) {
      this.nama_app_infra = localStorage.getItem('nama_app_infra')
      this.baseColumns[2].filterOptions.filterValue = this.nama_app_infra
    }
    if (localStorage.getItem('nama')) {
      this.nama_app_infra = localStorage.getItem('nama')
      this.baseColumns[2].filterOptions.filterValue = this.nama_app_infra
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.columns = this.baseColumns
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'persetujuanES2' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'persetujuanES2')
    this.getReference()
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.clearanceColors[status]) {
        return this.clearanceColors[status]
      }
      return {
        title: status,
        class: 'outlne-info',
      }
    },
    openFile(value) {
      this.fileKak = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.prefix !== '') {
        params.push({
          column: 'prefix',
          value: String(this.prefix),
        })
        localStorage.setItem('prefix', this.prefix)
      } else {
        localStorage.removeItem('prefix')
      }
      if (this.nama_kegiatan !== '') {
        params.push({
          column: 'nama_kegiatan',
          value: this.nama_kegiatan,
        })
        localStorage.setItem('nama_kegiatan', this.nama_kegiatan)
      } else {
        localStorage.removeItem('nama_kegiatan')
      }
      if (this.nama_app_infra !== '') {
        if (this.yearBudget === '2023') {
          params.push({
            column: 'nama_app_infra',
            value: this.nama_app_infra,
          })
          localStorage.setItem('nama_app_infra', this.nama_app_infra)
        } else {
          params.push({
            column: 'nama',
            value: this.nama_app_infra,
          })
          localStorage.setItem('nama', this.nama_app_infra)
        }
      } else {
        localStorage.removeItem('nama_app_infra')
        localStorage.removeItem('nama')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi_prefix') {
          columName = 'prefix'
        } else if (param.field === 'nama') {
          if (this.yearBudget === '2023') {
            columName = 'nama_app_infra'
          } else {
            columName = 'nama'
          }
        } else if (param.field === 'anggaran') {
          columName = 'total_anggaran'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi_prefix') {
          columName = 'prefix'
        } else if (key === 'nama') {
          if (this.yearBudget === '2023') {
            columName = 'nama_app_infra'
          } else {
            columName = 'nama'
          }
        }
        this[columName] = valuez
        if (valuez !== '') {
          filterz.push({
            column: columName,
            value: valuez,
          })
          localStorage.setItem(columName, valuez)
        } else {
          localStorage.removeItem(columName)
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/approval/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            res.data.data.data.map(value => {
              const rekodata = value
              if (this.userData.group_id === 51 && rekodata.takel_status !== 'Belum') {
                rekodata.mode = (rekodata.takel_status === 'Dilanjutkan') ? 'yes' : 'no' // takel
              } else if (this.userData.group_id === 52 && rekodata.laip_status !== 'Belum') {
                rekodata.mode = (rekodata.laip_status === 'Dilanjutkan') ? 'yes' : 'no' // laip
              } else if (this.userData.group_id === 53 && rekodata.asdep_status !== 'Belum') {
                rekodata.mode = (rekodata.asdep_status === 'Dilanjutkan') ? 'yes' : 'no' // asdep
              }
              this.rows.push(rekodata)
              return rekodata
            })
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    openRekomendasi(appData) {
      this.listRekomendasi = {}
      this.modalTitle = 'Daftar Rekomendasi Tim Teknis'

      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.domain === 'APLIKASI') {
        params.type = 'app'
        params.aplikasi_id = appData.id
      } else {
        params.type = 'infra'
        params.infrastruktur_id = appData.id
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      }
      this.$http.get('/recommendation/get-recommendations', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    openRekomendasiDirektur(appData, direktorate, label) {
      this.listRekomendasi = {}
      this.modalTitle = `Rekomendasi Direktur : ${label}`

      const formData = {
        domain: appData.domain,
        app_infra_id: appData.id,
        direktorat: direktorate,
      }
      if (appData.aplikasi_id) {
        formData.domain = 'APLIKASI'
        formData.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        formData.domain = 'INFRASTRUKTUR'
        formData.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        formData.domain = 'DATA'
        formData.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        formData.domain = 'NON-TEKNIS'
        formData.non_teknis_id = appData.non_teknis_id
      }
      this.$http.post('/approval/view', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi-direktur'].show()
          }
        })
    },
    openTambahRekomendasi(part, appData) {
      const field = document.querySelector(`input[name=${part}]:checked`)

      if (field === null) {
        this.alertText = 'Silahkan pilih rekomendasi "Dilanjutkan" atau "Tidak Dilanjutkan" terlebih dahulu'
        this.$refs['modal-warning'].show()
      } else {
        const cont = (field.value === 'yes') ? 1 : 0
        this.textRekomendasi = {
          program: appData,
          mode: field.value,
          type: (field.value === 'yes') ? 'CONTINUE' : 'PENDING',
          selectmode: (field.value === 'yes') ? 'Dilanjutkan' : 'Tidak Dilanjutkan',
          daftar: [],
          select: [],
          note: '',
        }
        this.modalTitle = `Rekomendasi Tambahan : ${this.textRekomendasi.selectmode} ~ ${appData.nama}`

        this.listRekoDir = {
          appData,
          formData: this.textRekomendasi,
        }
        let targetUrl = 'application-data'

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: appData.data_utama_id,
        }
        if (appData.domain === 'APLIKASI') {
          params.aplikasi_id = appData.id
          params.type = 'app'
        }
        if (appData.domain === 'INFRASTRUKTUR') {
          targetUrl = 'infrastructure-data'
          params.infrastruktur_id = appData.id
          params.type = 'infra'
        }
        if (appData.aplikasi_id) {
          targetUrl = 'application-data'
          params.type = 'app'
          params.aplikasi_id = appData.aplikasi_id
        } else if (appData.infrastruktur_id) {
          targetUrl = 'infrastructure-data'
          params.type = 'infra'
          params.infrastruktur_id = appData.infrastruktur_id
        } else if (appData.data_informasi_id) {
          targetUrl = 'information-data'
          params.type = 'data'
          params.data_informasi_id = appData.data_informasi_id
        } else if (appData.non_teknis_id) {
          targetUrl = 'non-teknis-data'
          params.type = 'non-teknis'
          params.non_teknis_id = appData.non_teknis_id
        }
        this.$http.get(`/recommendation/${targetUrl}`, {
          params,
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.textRekomendasi.select = []
              if (res.data.data.users_recommendations.length !== 0) {
                res.data.data.users_recommendations.map(usereco => {
                  if (this.textRekomendasi.select.indexOf(usereco.rekomendasi_id) === -1 && usereco.is_continue === cont) {
                    this.textRekomendasi.select.push(usereco.rekomendasi_id)
                  }
                  return true
                })
              }
            }
          })
        this.$http.get(`/recommendation/${targetUrl}/references?token=${localStorage.getItem('userToken')}`)
          .then(res => {
            if (res.data.status === 'success') {
              res.data.data.ref_recommendations.map(value => {
                if (value.tipe === this.textRekomendasi.type) {
                  const reko = {
                    rekomendasi_id: value.rekomendasi_id,
                    rekomendasi: value.rekomendasi,
                  }
                  this.textRekomendasi.daftar.push(reko)
                }
                this.$refs['modal-tambah-rekomendasi'].show()
                return true
              })
            }
          })
      }
    },
    konfirmReko(appData, mode) {
      const statusDir = (mode === 'no') ? 'Tidak Dilanjutkan' : 'Dilanjutkan'

      if (this.yearBudget === '2023') {
        this.selectedInput = `some-${appData.domain.toLowerCase()}-${appData.id}`
      } else {
        this.selectedInput = `some-${appData.jenis_pengadaan_id}-${appData.data_utama_id}`
      }

      let selectDomain = ''
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.domain === 'APLIKASI') {
        selectDomain = 'APLIKASI'
        params.type = 'app'
        params.aplikasi_id = appData.id
      } else if (appData.domain === 'INFRASTRUKTUR') {
        selectDomain = 'INFRASTRUKTUR'
        params.type = 'infra'
        params.infrastruktur_id = appData.id
      }
      if (appData.aplikasi_id) {
        selectDomain = 'APLIKASI'
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        selectDomain = 'INFRASTRUKTUR'
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        selectDomain = 'DATA'
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        selectDomain = 'NON-TEKNIS'
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      }
      this.$http.get('/recommendation/get-recommendations', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            let openreko = false
            this.listRekomendasi = res.data.data

            if (mode === 'yes' && this.listRekomendasi.continue.length === 0) {
              openreko = true
            } else if (mode === 'no' && this.listRekomendasi.postpone.length === 0) {
              openreko = true
            }

            if (statusDir !== appData.teknis_status && openreko) {
              this.openTambahRekomendasi(this.selectedInput, appData)
            } else {
              const formData = {
                domain: selectDomain,
                id: appData.id,
                tipe_rekomendasi: (mode === 'yes') ? 1 : -1,
                data_utama_id: appData.data_utama_id,
              }
              if (appData.domain === 'APLIKASI') {
                formData.aplikasi_id = appData.id
              } else if (appData.domain === 'INFRASTRUKTUR') {
                formData.infrastruktur_id = appData.id
              }
              if (appData.aplikasi_id) {
                formData.aplikasi_id = appData.aplikasi_id
              } else if (appData.infrastruktur_id) {
                formData.infrastruktur_id = appData.infrastruktur_id
              } else if (appData.data_informasi_id) {
                formData.data_informasi_id = appData.data_informasi_id
              } else if (appData.non_teknis_id) {
                formData.non_teknis_id = appData.non_teknis_id
              }
              this.listRekoDir = {
                appData,
                formData,
              }
              this.$refs['modal-confirm'].show()
            }
          }
        })
    },
    simpanReko() {
      this.$http.post('/approval/submit', this.listRekoDir.formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.$refs['modal-confirm'].hide()
            this.$refs['modal-warning2'].show()
            this.listRekoDir = ''

            setTimeout(() => {
              this.errorStat = false
              this.errorMsg = ''
              this.doFilter()
              this.$refs['modal-warning2'].hide()
            }, 1000)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    cancelReko() {
      if (this.listRekoDir !== '') {
        if (document.querySelector(`input[name="${this.selectedInput}"]`)) {
          document.querySelector(`input[name="${this.selectedInput}"]:checked`).checked = false
        }
        this.selectedInput = ''
        this.listRekoDir = ''

        this.$refs['modal-confirm'].hide()
      }
    },
    updateResult() {
      this.doFilter()
      this.$refs['modal-confirm'].hide()
      this.$refs['modal-tambah-rekomendasi'].hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
